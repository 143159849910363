import React, { useEffect, useState } from 'react';
import './AboutMe.css';

const AboutMe = ({ isDarkMode }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [currentText, setCurrentText] = useState('');
  const [cursor, setCursor] = useState('_');
  const [currentColor, setCurrentColor] = useState('#ff1a1a');

  useEffect(() => {
    const initImageSrc = isDarkMode ? '/me-black.png' : '/me.png';
    setImageSrc(initImageSrc);

    const colors = ['#ff1a1a', '#8a63d2', '#50e3c2', '#f81ce5', '#0761d1'];
    const words = ['Eat.', 'Sleep.', 'Code.', 'Repeat.'];
    let currentWordIndex = 0;
    let letterIndex = 0;
    let isDeleting = false;

    const intervalId = setInterval(() => {
      const currentWord = words[currentWordIndex];
      setCursor('_');
      setCurrentText(currentWord.substring(0, letterIndex));

      if (!isDeleting && letterIndex === currentWord.length) {
        isDeleting = true;
      } else if (isDeleting && letterIndex === 0) {
        isDeleting = false;
        currentWordIndex = (currentWordIndex + 1) % words.length;
        const randomIndex = Math.floor(Math.random() * colors.length);
        setCurrentColor(colors[randomIndex]);
      }

      letterIndex = isDeleting ? letterIndex - 1 : letterIndex + 1;
    }, 300);

    return () => clearInterval(intervalId);
  }, [isDarkMode]);

  return (
    <>
      <div className='spacer21' />
      
      <div className={`aboutMe ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className='center-div'>
          <div className="leftImageAbout">
            <img className="myImage" src={imageSrc} alt="My Memoji" />
          </div>
          <div className="quote">
            <div className="tt" style={{ color: currentColor }}>
              {currentText}
              <span className={cursor === '_' ? 'blinking-cursor' : ''}>{cursor}</span>
            </div>
          </div>
          <div className="rightTextAbout">
            <div className="aboutmeHead">
              Hi, I'm Rohan, <span>CS Student</span> & former <span>Software Engineer Intern at <span className="amazonspan"><i className="fa-brands fa-amazon"></i> Amazon</span></span> on the ConnQA Team. 
            </div>
            <div className="aboutmeBody">
              As a student leader working in different organizations,<br/>I enjoy connecting the dots: be it ideas from different disciplines or people from different teams!
            </div>
            <div className="aboutmeBorder"></div>
            <div className="aboutmeHead">
              In the past, I've worked as <span>Software Engineer</span> at SCAI, Arizona State University. 
            </div>
            
            <div className="social-icons">
              <a href="https://github.com/rohangosavi01" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github"></i>
              </a>
              <a href="https://www.linkedin.com/in/rohan01/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://www.instagram.com/rohangosavi01/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="mailto:me@rohangosavi.com" target="_blank" rel="noopener noreferrer">
                <i className="fa-solid fa-at"></i>
              </a>
            </div>

            <div className="resume-about">
              <div className="resume-button-1">
                 <a target="_blank" href="resume.pdf">My Resume</a>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="spacer show-no"></div>
    </>
  );
};

export default AboutMe;
