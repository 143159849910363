// Import packages and styles
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import './Header.scss';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const Header = ({ isDarkMode, setDarkMode }) => {
  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
  };

  return (
    <header data-theme={isDarkMode ? 'dark' : 'light'}>
      <div className="header-inner">
      <div className="nameRohan">
        <Link to="/">
          <h1 className="headerName">
            <span className="firstName">ROHAN</span>
            <span className="lastName">GOSAVI</span>
          </h1>
        </Link>
      </div>
      <nav id="myTopnav">
        <ul className="nav__links">
          <li className="about">
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/work">Work</Link>
          </li>
          <li id="project-click">
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <DarkModeSwitch
              checked={isDarkMode}
              onChange={toggleDarkMode}
              size={24}
            />
          </li>
          <li className="resume">
            <a 
              target="_blank" 
              rel="noopener noreferrer" 
              href={`${process.env.PUBLIC_URL}/resume.pdf`}
            >
              Resume
            </a>
          </li>
        </ul>
      </nav>
      </div>
    </header>
  );
};

export default Header;
