import React from 'react';
import './Footer.css';

const Footer = ({ isDarkMode }) => {
  return (
    <footer data-theme={isDarkMode ? 'dark' : 'light'}>
      <div className="footer-content">
        <p>© 2023 Rohan Gosavi. All Rights Reserved.</p>
        <ul className="nav__links footer_navlink">
          <li><a href="mailto:me@rohangosavi.com">me@rohangosavi.com</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
