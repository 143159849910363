import './Projects.css'; 
import React, { useState } from 'react';
import AnimationforWork from '../animations/AnimationforWork';


const Projects = ({ isDarkMode }) => {

  const projectData = [
    {
      link: 'https://github.com/rohangosavi01/url-shortening-project',
      logo: '/ml.png',
      title: 'Mini Links',
      description: 'Leveraged Flask and Google Maps to create an innovative full-stack application that shortens URLS by 98% and integrated SQLite3 Database to manage over 1.6 million links, enabling easier link sharing for end users.',
      languages: ['GitHub','SQLite3','Python', 'HTML'],  
      languageIcons: ['fab fa-github','fas fa-database','fab fa-python', 'fab fa-html5']  
    },
    {
      link: 'https://github.com/rohangosavi01/covid_hackwest',
      logo: '/hb.png',
      title: 'Health Bridge',
      description: 'Designed and developed a dynamic, interactive COVID-19 Symptom Survey app using 20-point diagnosis. Integrated Google Maps API to redirect users to nearby health care centers based on geo-location and suggest precautions for staying safe from virus.',
      languages: ['GitHub', 'Python', 'Google Maps API', 'CSS'],  // Add this line
      languageIcons: ['fab fa-github','fab fa-python', 'fas fa-location-dot', 'fab fa-css3']  // Add this line
    },
    {
      link: 'https://github.com/rohangosavi01/zendesk-ticket-viewer',
      logo: '/zendesk.png',
      title: 'Zendesk Ticket Viewer',
      description: "Designed and developed a static Zendesk Ticket Viewer web application as part of Zendesk's Online Assessment (OA), using Python and Flask. The application fetches and displays company tickets through the Zendesk API, offering additional details upon request.",
      languages: ['GitHub','Python', 'JavaScript', 'HTML', 'CSS'],  // Add this line
      languageIcons: ['fab fa-github','fab fa-python', 'fab fa-js', 'fab fa-html5', 'fab fa-css3']  // Add this line
    },
    {
      link: 'https://github.com/rohangosavi01/live-weather-application',
      logo: '/wm.png',
      title: 'Weather Max',
      description: 'Developed a static Live Weather Viewing Application as an individual project, utilizing Python and Flask to connect with OpenWeather API. The application provides real-time weather updates and 30-day forecasts for over 200,000 cities globally.',
      languages: ['GitHub','Python', 'HTML', 'CSS'],  // Add this line
      languageIcons: ['fab fa-github','fab fa-python', 'fab fa-html5', 'fab fa-css3']  // Add this line
    },
    {
      link: 'https://www.rohangosavi.com',
      logo: '/me.png',
      title: 'Portfolio Website',
      description: 'This Website!',
      languages: ['GitHub','React', 'JavaScript','HTML', 'CSS'],  // Add this line
      languageIcons: ['fab fa-github','fab fa-react', 'fab fa-js','fab fa-html5', 'fab fa-css3']
    }
  ];

  return (
    <>
      <div className="spacery4">
        <div className='headding'>
          <AnimationforWork isDarkMode={isDarkMode} />
          <div className="textDiv">
            <h1> Projects </h1>
          </div>
        </div>
      </div>

      <div className="container">
        {projectData.map((project, index) => (
          <a key={index} href={project.link} target="_blank" rel="noopener noreferrer">
            <div className="card">
              <svg className="with-icon_icon__MHUeb link-icon" onClick={() => {window.open(project.link, '_blank');}} data-testid="geist-icon" fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"> 
                <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" />
                <path d="M15 3h6v6" />
                <path d="M10 14L21 3" />
              </svg>

              <div className="logo-container">
                <img src={isDarkMode ? project.logo.replace('.png', '-black.png') : project.logo} alt={project.title} className="company-logo" />
              </div>
                
                <div className="info-container">
                  <div className="position">
                    <strong>{project.title}</strong> &nbsp;
                    <svg className="with-icon_icon__MHUeb link-icon2" onClick={() => { window.open(project.link, '_blank'); }} data-testid="geist-icon" fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                      <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" />
                      <path d="M15 3h6v6" />
                      <path d="M10 14L21 3" />
                    </svg>
                  </div>
                    
                  <div className="company-name projects">
                    {project.description}
                  </div>

                  <div className="languages">
                    {project.languageIcons.map((icon, langIndex) => ( <i key={langIndex} className={icon}></i> ))}
                  </div>
                </div>

            </div>
          </a>
        ))}
      </div>
      
      <div className="spacer">
      </div>
    </>
  );
};

export default Projects;
