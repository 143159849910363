import React, { useRef, useEffect } from 'react';
import './CanvasAnimation.css';

const CanvasAnimation = ({ isDarkMode }) => {

  const canvasRef = useRef(null);

  useEffect(() => {
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let ww = canvas.width = canvas.offsetWidth;
    let wh = canvas.height = canvas.offsetHeight;
    let particles = [];
    let amount = 0;
    let mouse = { x: 0, y: 0 };

    // Color based on the theme
    let colors = isDarkMode
      ? ["#aaffec", "#79ffe1", "#50e3c2", "#29bc9b"]
      : ["#d3e5ff", "#3291ff", "#0070f3", "#0761d1"];

    ww = canvas.width = canvas.offsetWidth;
    wh = canvas.height = canvas.offsetHeight;

    function Particle(x,y){
      this.x = x;
      this.y = y;
      this.dest = {
        x : x,
        y : y,
      };
      this.r = Math.random()*4 + 2;
      this.vx = 2;  
      this.vy = 2;  
      this.accX = 1;
      this.accY = 2;
      this.friction = Math.random()*0.04 + 0.94;
      this.color = colors[Math.floor(Math.random()*colors.length)];
    }

    Particle.prototype.render = function() {
      this.accX = (this.dest.x - this.x)/200;
      this.accY = (this.dest.y - this.y)/200;
      this.vx += this.accX;
      this.vy += this.accY;
      this.vx *= this.friction;
      this.vy *= this.friction;
      
      this.x += 1*this.vx;
      this.y += 1*this.vy;

      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.r, Math.PI * 2, false);
      ctx.fill();
      
      var a = this.x - mouse.x;
      var b = this.y - mouse.y;
      
      var distance = Math.sqrt( a*a + b*b );
      const radius = 100;
      if(distance < (radius)){
        this.accX = (this.x - mouse.x)/100;
        this.accY = (this.y - mouse.y)/100;
        this.vx += this.accX;
        this.vy += this.accY;
      }
    }

    function onMouseMove(e){
      var rect = canvas.getBoundingClientRect();
      mouse.x = e.clientX - rect.left;
      mouse.y = e.clientY - rect.top;
      console.log("Mouse moved!", mouse.x, mouse.y);
    }

    function onTouchMove(e){
      if(e.touches.length > 0 ){
        mouse.x = e.touches[0].clientX;
        mouse.y = e.touches[0].clientY;
      }
    }

    function onTouchEnd(e){
      mouse.x = -9999;
      mouse.y = -9999;
    }

    function initScene(){
      const computedStyle = getComputedStyle(canvas);
      ww = canvas.width = parseInt(computedStyle.width, 10);
      wh = canvas.height = parseInt(computedStyle.height, 10);
      
      
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      const baseFontSize = 10;
      const scaledFontSize = Math.max(baseFontSize, ww / 6);
      
      ctx.font = `bold ${scaledFontSize}px sans-serif`;
      ctx.textAlign = "center";

      ctx.fillText("Hello World!", ww/2, wh/2);
      
      let data;
      data = ctx.getImageData(0, 0, 1080, 500).data;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = "screen";
      
      particles = [];
      for(var i = 0; i < ww; i += Math.round(ww / 100)){
        for(var j = 0; j < wh; j += Math.round(ww / 100)){
          if(data[ ((i + j * ww) * 4) + 3] > 150){
            
            particles.push(new Particle(i, j));
          }
        }
      }
      amount = particles.length;
    }

    function render(a) {
      requestAnimationFrame(render);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (var i = 0; i < amount; i++) {
        particles[i].render();
      }
    }

    // Event Listeners
    window.addEventListener("resize", initScene);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchmove", onTouchMove);
    window.addEventListener("touchend", onTouchEnd);

    // Initialize and Render
    initScene();
    requestAnimationFrame(render);
  }, [isDarkMode]); 

  return (
    <div className="scene-back">
      <canvas ref={canvasRef} className="scene" id="scene"></canvas>
    </div>
  );
};

export default CanvasAnimation;
