import React, { useEffect } from 'react';
import './ResumeRedirect.css';

function ResumeRedirect() {
  useEffect(() => {
    // Redirect the user to the resume PDF
    window.location.href = '/resume.pdf';
  }, []);

  return (
    <div className="redirect-container">
      <p>Redirecting to resume...</p>
    </div>
  );
}

export default ResumeRedirect;
