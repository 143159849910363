import React, { useState, useEffect } from 'react';
import AnimationforWork from '../animations/AnimationforWork'

const Work = ({ isDarkMode }) => {
  const [isActive, setIsActive] = useState(false);
  const toggleCardState = () => {
    setIsActive(!isActive);
  };

  const workItems = [
    {
      link: 'https://www.aboutamazon.com/',
      logo: '/amazon.png',
      position: 'Software Engineer Intern',
      description: "Initiated the development of an SQL Engine to process data in an AWS S3 Bucket, resulting in an 80% reduction in query execution time compared to the current module. Collaborated with a cross-functional team to gather requirements and design the architecture and implemented a modular codebase for easy integration of future developments.<br/><br/>Developed APIs in an internal framework to monitor device memory usage during test suites on Amazon Devices. The script generated logs, csv files, and graphs for analysis, allowing access to memory consumption across multiple runs and identifying common patterns. Leveraged SDLC Process for a smooth delivery.<br/><br/>Developed a Journal File parser using regular expressions to enable parsing of Journal Logs, optimizing data storage into a tuple-based dictionary structure for efficient processing.",
      companyName: 'Amazon.com Services LLC'
    },
    {
      link: 'https://www.asu.edu/',
      logo: '/asu.png',
      position: 'Software Engineer',
      description:"Developed Python scripts for data scraping, reading RSS Raw feed and storage from over 50 space related websites, resulting in the creation of a comprehensive database. The scripts efficiently extracted relevant information, providing valuable insights for market analysis and decision-making processes to external stake holders.<br/><br/>Leveraged NLP techniques like Spacy, GPT, and NLTK to analyze and format data for web dashboard development, enhancing data visualization and insights.",
      companyName: 'SCAI, Arizona State University'
    },
    {
      link: 'https://www.ttu.edu/',
      logo: '/texas.png',
      position: 'Front End Developer',
      description: "Designed and developed a primary website using HTML5, JavaScript, and OmniTools, leveraging strong coding skills to ensure user-friendliness and a seamless browsing experience. Implemented responsive design techniques to guarantee website compatibility across multiple devices, providing an optimal viewing experience for users on desktops, tablets, and mobile devices.",
      companyName: 'Student Government Association, Texas Tech University'
    },
    {
      link: 'https://www.depts.ttu.edu/sga/',
      logo: '/sga.png',
      position: 'Senator, Engineering',
      description: "Represented the students at the College of Engineering during the 57th Student Senate Session, effectively voicing student concerns and advocating for classroom improvements. Acted as a liaison between university administration and students.<br/><br/>Initiated programs that aimed at enhancing the quality of life for under-represented students, leading to a 30% increase in student engagement. This improvement was measured through student surveys, demonstrating positive impact of the initiatives.",
      companyName: 'Student Government Association, Texas Tech University'
    },
    {
      link: 'https:/planetinteractive.us/',
      logo: '/fact.png',
      position: 'UI Developer Intern',
      description: "Created new student courses using Moodle Tools for website, course management system over student & trainer feedback.<br/><br/>Analyzed enrollment for different time zones to create optimum classes by liasoning with coordinators, managers & instructors.<br/><br/>Developed model website using HTML, CSS, and Bitrix Tools to ensure user-friendliness.",
      companyName: 'Planet Interactive'
    }
  ];
  return (
    
    <>
    
      <div className="spacery4">
        
        <div className='headding'>

          <AnimationforWork isDarkMode={isDarkMode} />

          <div className="textDiv">
            <h1> Work </h1>
            <div className="expandButton" onClick={toggleCardState}>
              {isActive ? <>Show Less</> : <>Show More</>}
          </div>

          </div>

          

        </div>
      </div>
      

    <div className="container">
      {workItems.map((item, index) => (
        <a href={item.link} target="_blank" key={index} rel="noopener noreferrer">
          <div className={`card ${isActive ? 'active' : ''}`}>
            <svg className="with-icon_icon__MHUeb link-icon" onClick={() => { window.open(item.link, '_blank'); }} data-testid="geist-icon" fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
              <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" />
              <path d="M15 3h6v6" />
              <path d="M10 14L21 3" />
            </svg>
        
            <div className="logo-container">
              
              <img src={isDarkMode ? item.logo.replace('.png', '-black.png') : item.logo} alt={item.companyName} className="company-logo" />
            </div>

            <div className="info-container">
              <div className="position">
                <strong>{item.position}</strong> &nbsp;
                  <svg className="with-icon_icon__MHUeb link-icon2" onClick={() => { window.open(item.link, '_blank'); }} data-testid="geist-icon" fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" />
                    <path d="M15 3h6v6" />
                    <path d="M10 14L21 3" />
                  </svg>
                </div>
              
              <div className="company-description">{item.description.split('<br/>').map((line, i) => (
                  <span key={i}> {line} <br/> </span> ))}</div>
              <div className="company-name forwork">{item.companyName}</div>
            </div>
          </div>
        </a>
      ))}
    </div>

    <div className="spacery456"></div>
  </>
  );
};

export default Work;
