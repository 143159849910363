import React, { useState, useEffect } from 'react';

const AnimationforWork = ({ isDarkMode }) => {
  const [currentText, setCurrentText] = useState('');
  const [cursor, setCursor] = useState('_');
  let currentColor = isDarkMode ? "#50e3c2" : "#3291ff";

  useEffect(() => {
    const words = ['Eat. Sleep. Code. Repeat.'];
    let currentWordIndex = 0;
    let letterIndex = 0;
    let isDeleting = false;

    const intervalId = setInterval(() => {
      const currentWord = words[currentWordIndex];
      setCursor('_');
      setCurrentText(currentWord.substring(0, letterIndex));

      if (!isDeleting && letterIndex === currentWord.length) {
        isDeleting = true;
      } else if (isDeleting && letterIndex === 0) {
        isDeleting = false;
        currentWordIndex = (currentWordIndex + 1) % words.length;
      }

      letterIndex = isDeleting ? letterIndex - 1 : letterIndex + 1;
    }, 300);

    return () => clearInterval(intervalId);
  }, [isDarkMode]);

  return (
    <div className="terminal-text qq" style={{ color: currentColor }}>
      {currentText}
      <span className={cursor === '_' ? 'blinking-cursor' : ''}>{cursor}</span>
    </div>
  );
};

export default AnimationforWork;
