import React, { useState, useEffect } from 'react';
import './ScrollToTop.css';

const ScrollToTop = ({ isDarkMode }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
        <>
            <div className="abc">
                {showScrollToTop && (
                <button className="scroll-to-top" onClick={scrollToTop}>
                <i className="fa-solid fa-angle-up"></i>
                </button>

                )}
            </div>
        </>
  );
};

export default ScrollToTop;
